.cart-component {
    width: 40%;
    min-height: 92.2vh;
}

.cart-component > .cart-container {
    width: 50%;
    padding: 3% 0 0;
}

.cart-component > .cart-container > .cart-total-container > .row {
    margin-left: 50%;
}

.cart-component .cart-food-container > .row > .col:first-child {
    flex-grow: 0;
}

.cart-food-container > .row > .col > img {
    width: 50%;
    border-radius: 15px;
}

.cart-summary-container {
    box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {
    .cart-component > .cart-container {
        width: 59% !important;
    }
}

@media (max-width: 768px) {
    .cart-component {
        box-shadow: none;
    }

    .cart-component > .cart-container {
        width: 100% !important;
        position: relative;
    }

    .cart-component > .cart-container > .cart-total-container > .row {
        margin-left: 0;
    }

    .cart-food-container > .row > .col:first-child {
        padding: 1%;
    }

    .cart-component > .cart-container > .cart-total-container > .row > .col:first-child {
        padding-left: 0;
    }

    .desktop-cart-summary-container {
        display: none;
    }
}