.admin-container, .admin-container > .admin-container-navbar {
    min-height: 92.2vh;
}

.admin-container-navbar {
    display: flex;
}

.vertical-nav {
    background-color: black;
    text-align: center;
    padding: 0;
    width: 15%;
}

.vertical-nav > .nav {
    position: fixed;
    width: 15%;
}

.vertical-nav .nav-link {
    color: white !important;
    margin-bottom: 5%;
    text-align: left;
    padding-left: 10%;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #ef0000;
    border-radius: 0;
}

.nav-detail-container {
    width: 80%;
    padding: 2%;
}

.admin-table > thead > tr > th:last-child, .admin-table > tbody > tr > td:last-child {
    text-align: right;
    width: 15%;
}

.admin-table > thead > tr > th:first-child {
    text-align: left;
}

.admin-table tr {
    border: 1px solid #707070;
}

.admin-table th, .admin-table td {
    padding: 1%;
}

.admin-table > thead > tr > th:first-child > h1 {
    margin-bottom: 0;
}

.table-button {
    width: 100%;
}

.kitchen-status-button {
    width: 100%;
    margin-bottom: 5%;
}

.kitchen-status-container > .kitchen-status-button:first-child {
    margin-right: 2%;
}

.kitchen-status-container > .kitchen-status-button:nth-child(2) {
    margin-left: 2%;
}

.admin-form {
    width: 30%;
}

.form-heading {
    margin-bottom: 10%;
}

.form-button {
    width: 100%;
    margin-bottom: 5%;
}

.action-column > span {
    cursor: pointer;
}

.edit-button {
    margin-right: 10%;
}

.select-column, .image-column, .filter-input-column {
    width: 18%;
}

.image-column > img {
    width: 50%;
}

.order-modal-body > table > tbody > tr > td:nth-child(3) {
    text-align: right;
}

.order-modal-body > table > tbody > tr {
    height: 35px;
}

.order-modal-footer {
    padding: 5% 8%;
}

.latest-order-body {
    border-radius: 15px;
    margin-bottom: 5%;
    padding: 7%;
    border: 1px solid #F5F5F5;
}

.portal-nav-detail-container {
    padding: 0;
}

.portal-nav-detail-container > .tab-content {
    margin: 1% 0;
    max-width: 100%;
}

.latest-order-heading-container {
    background-color: #ceced0;
    text-align: center;
    position: fixed;
    z-index: 2;
    width: 78.4%;
}

.latest-order-container {
    padding-top: 6%;
}

.latest-order-heading-container h1 {
    margin: 0;
}

.latest-order-heading-container > .p-2, .latest-order-container > .p-2 {
    width: calc(100% / 3);
}

.order-status {
    margin: 2% 0 !important;
}

.date-column {
    width: 13%;
}

.order-no-column {
    width: 10%;
}

.Confirmed-container {
    box-shadow: 6px 6px 0 0 rgb(28 204 23);
}

.Failed-container {
    box-shadow: 6px 6px 0 0 rgb(239 0 0);
}

.PaymentPending-container, .OTPPending-container {
    box-shadow: 6px 6px 0 0 rgb(255 111 1);
}

.admin-tab-content {
    margin: 0;
}

.banner-image-input > .custom-file-label > img {
    width: 2%;
}

.color-code-container > .p-2 > svg {
    margin-right: 8%;
}

.delivery-table {
    margin-top: 5%;
}

.admin-table-heading {
    width: 25%;
}

.order-detail-table {
    margin: 5% 0;
}

.finance-table > tbody > tr > td > span {
    padding-left: 5%;
}

.finance-table > tbody > tr > td:last-child {
    width: auto;
}

.select-offer-component {
    padding: 0 2% 2%;
}

@media (max-width: 1366px) {
    .latest-order-container {
        padding-top: 9%;
    }

    .nav-detail-container {
        width: 85%;
    }

    .latest-order-heading-container {
        width: 82%;
    }

    .order-no-column {
        width: auto;
    }

    .image-column > img {
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .admin-container, .admin-container > .admin-container-navbar {
        min-height: 94.15vh;
    }

    .portal-nav-detail-container > .tab-content {
        margin: 0;
    }

    .vertical-nav {
        margin-top: -1%;
    }

    .latest-order-container {
        padding-top: 12%;
    }

    .admin-form {
        width: 50%;
    }
}