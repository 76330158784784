.order-container {
    margin-top: 7%;
}

.Confirmed {
    color: #00DC2C;
}

.Failed {
    color: #ef0000;
}

.PaymentPending {
    color: orange;
}

.order-table-container {
    width: 60%;
}

.order-table-container > div {
    padding: 2%;
    background-color: #F9F9F9;
    border-radius: 15px;
}

.order-table > thead > tr > th, .order-table > tbody > tr > td {
    padding: 1%;
}

.order-table > tbody > tr > td {
    border: none !important;
}

.total-row {
    border-top: 2px solid #E2E2E2;
}

.total-row > td {
    padding-top: 2% !important;
}

.food-name-column {
    width: 50%;
}

@media (max-width: 1024px) {
    .order-container {
        margin-top: 10%;
    }
}

@media (max-width: 768px) {
    .order-container > .d-flex {
        display: block !important;
    }

    .order-table-container {
        margin: 10% 0;
        width: 100%;
    }

    .food-name-column {
        width: 25%;
    }
}