#root {
    font-family: 'Suez One';
}

#body {
    margin: 3.8% 0 0;
}

.nav-link {
    color: black !important;
}

.nav-link:hover {
    color: rgba(0, 0, 0, .7) !important;
}

hr {
    border-top: 2px solid #ef0000;
    margin: 3% 0;
}

.grey-hr {
    border-top: 2px solid #E2E2E2;
}

.red-font {
    color: #ef0000 !important;
}

.grey-font {
    color: #ceced0 !important;
}

.nav-link.red-font:hover {
    color: rgba(239, 0, 0, .7) !important;
}

h1 {
    font-size: 30px;
    margin-bottom: 5%;
}

h2 {
    font-size: 24px;
}

.red-button, .white-button:hover {
    background-color: #ef0000;
    color: white;
    border-radius: 15px;
}

.red-button:hover, .white-button {
    background-color: white;
    color: #ef0000;
    border-color: #ef0000;
    border-radius: 15px;
}

.red-button > .nav-link, .white-button:hover > .nav-link {
    color: white !important;
}

.red-button:hover > .nav-link, .white-button > .nav-link {
    color: #ef0000 !important;
}

.black-button {
    background-color: black;
    color: white;
    border-radius: 15px;
}

.black-font-button {
    background-color: white;
    color: black;
    border-radius: 15px;
    border-color: #E9E9E9;
}

.black-button:hover, .black-font-button:hover {
    background-color: white;
    color: black;
    border-color: black;
    border-radius: 15px;
}

.green-button {
    background-color: #00DC2C;
    color: white;
    border-radius: 15px;
}

.green-button:hover {
    background-color: white;
    color: #00DC2C;
    border-color: #00DC2C;
    border-radius: 15px;
}

.black-form {
    border: 1px solid black;
}

.inline-button-input {
    border-radius: 15px 0 0 15px !important;
}

input, .select > div:first-child, .css-1pahdxg-control {
    border-radius: 15px !important;
    height: inherit !important;
    font-family: 'Nunito Sans';
}

.select {
    padding: 0;
    height: inherit;
}

.nunito-sans-font {
    font-family: 'Nunito Sans';
}

.segoe-ui-font {
    font-family: 'Segoe UI';
}

.capital-text {
    text-transform: capitalize;
}

.uppercase-text {
    text-transform: uppercase;
}

.custom-file-label {
    background-color: #EEEEEE;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    cursor: pointer;
}

.custom-file, .custom-file-label, .custom-file-input {
    height: 50vh;
}

.small-input, .small-input > .custom-file-label, .small-input > .custom-file-input {
    height: 8vh;
    margin: 3% 0;
}

.small-input > .custom-file-label > img {
    width: 20%;
}

.small-input > .custom-file-label > .image-uploader-label {
    font-size: 12px;
}

.custom-file-label::after {
    display: none;
}

.custom-file-input {
    padding: 0 !important;
    cursor: pointer;
    z-index: 0;
}

.custom-number {
    display: flex;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.custom-number-input, .custom-number > button {
    text-align: center;
    font-weight: 900;
    background: transparent;
    border: none;
    font-family: 'Nunito Sans';
    outline: none;
}

.custom-number > button {
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    color: #B9B9B9;
}

.custom-number > button:hover {
    color: #ef0000;
    border: 1px solid #ef0000;
}

.custom-number-input {
    border-left: none;
    border-right: none;
}

.custom-control-label {
    font-family: 'Nunito Sans';
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #ef0000;
    background-color: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
    border-color: #ef0000;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ef0000;
}

.custom-textarea {
    border-radius: 15px;
    font-family: 'Nunito Sans';
}

.remove-food-button {
    border: none !important;
    color: #ef0000 !important;
    margin-right: 5%;
}

@media (max-width: 1366px) {
    #body {
        margin-top: 5.8%;
    }
}

@media (max-width: 1024px) {
    #body {
        margin-top: 7.8%;
    }

    .small-input > .custom-file-label > .image-uploader-label {
        font-size: 9px;
    }

    .custom-number-input {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 85%;
    }
}

@media (max-width: 768px) {
    #body {
        margin: 17% 0 0;
    }
}

@media (max-width: 768px) and (min-height: 1024px) {
    #body {
        margin: 13.4% 0 0;
    }
}