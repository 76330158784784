.menu-container {
    width: 75%;
    margin-right: 1%;
    padding: 0 !important;
}

.menu-container > img {
    width: 95%;
}

.menu {
    margin: 2% 0;
}

.food-type-list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    cursor: pointer;
}

.food-type-list.active {
    cursor: grabbing;
}

.food-type-list > button {
    margin-right: 2%;
    min-width: max-content;
}

.food-type-detail-list-container {
    margin: 2% 0;
}

.food-type-detail-container {
    margin-bottom: 5%;
}

.food-type-detail-container > h1 {
    margin-bottom: 2%;
}

.cart-page-container {
    width: 25%;
    box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.05);
    min-height: 92.2vh;
}

.carousel-root {
    margin-top: 2%;
}

.carousel .slide img {
    border-radius: 15px;
}

.carousel .control-dots .dot {
    width: 10px !important;
    height: 10px !important;
    box-shadow: none !important;
}

@media (max-width: 1024px) {
    .menu-container {
        width: 65%;
        margin-right: 0;
    }

    .cart-page-container {
        width: 35%;
        min-height: 94.15vh;
    }

    .food-type-list {
        overflow-x: auto;
    }
}

@media (max-width: 768px) {
    .food-type-list {
        overflow-x: auto;
    }

    .menu-container > img {
        width: 100%;
    }

    .carousel * {
        height: 10vh;
    }

    .carousel .slide img {
        border-radius: 10px;
        object-fit: cover;
    }

    .carousel .control-dots {
        margin: 0 !important;
        display: none;
    }

    .desktop-cart-page-container {
        display: none;
    }
}