.navbar-header {
    background-color: white;
}

.navbar-links > .nav-item:first-child, .navbar-links > .nav-item:first-child > .nav-link {
    padding-left: 0 !important;
}

.nav.justify-content-end > .nav-item:last-child > .nav-link {
    padding-right: 0;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    border-color: transparent;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10%;
    border-radius: 15px;
    width: max-content;
}

.navbar-brand-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.navbar-brand {
    margin: 0;
}

.navbar-brand > img {
    height: 7vmin;
}

.navbar-light .navbar-toggler {
    border-color: transparent;
}

.navbar-light .navbar-toggler:focus {
    outline: none;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(239, 0, 0, 1)' stroke-width='5' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.branch-phone-number > span > img {
    margin-right: 10%;
    width: 25px;
    height: 25px;
}

span.nav-link:hover {
    color: black !important;
}

.cart-link-container {
    width: 40%;
}

.logged-in-cart-link-container {
    width: 25%;
}

.cart-link {
    width: 55%;
    margin: 0 auto;
}

.no-branch-cart-link-container > .cart-link {
    width: 100%;
}

.cart-link > img {
    width: 75%;
}

.badge {
    position: absolute;
    border-radius: 50%;
    background-color: #ef0000;
    color: white;
    font-size: 15px;
    font-family: 'Nunito Sans';
}

.phone-icon {
    width: 25%;
}

.mobile-navbar {
    display: none;
}

@media (max-width: 991px) {
    .mobile-navbar {
        display: flex;
    }

    .desktop-navbar {
        display: none;
    }

    .cart-link-container {
        width: 15%;
    }

    .navbar-collapse {
        min-height: 92vh;
        text-align: center;
        margin-top: 10%;
    }

    .dropdown-menu {
        box-shadow: none;
        text-align: center;
        padding: 5% 0;
    }

    .navbar-nav .dropdown-menu {
        margin: auto;
    }

    .dropdown:hover .dropdown-menu {
        display: none;
    }

    .dropdown-menu.show {
        display: block !important;
    }

    .navbar-brand {
        margin: 0;
    }

    .navbar-brand > img {
        height: 10vmin;
    }

    .branch-phone-number > .nav-link {
        padding: 0;
    }

    .cart-link {
        width: 12%;
        margin-left: 3%;
    }

    .branch-phone-number {
        margin-left: 5%;
    }

    .branch-phone-number > span > img {
        margin-right: 5%;
    }

    .navbar-links > .nav-item:first-child {
        padding: .5rem !important;
    }
}

@media (max-width: 768px) {
    .cart-link {
        width: 20%;
    }
}

@media (max-width: 320px) {
    .navbar-toggler {
        padding: 1%;
    }

    .branch-phone-number, .cart-link {
        margin-left: 1%;
    }
}

@media (max-width: 768px) and (min-height: 1024px) {
    .cart-link {
        width: 15%;
    }
}