.summary-total-container {
    margin: 10% 0;
}

.summary-total-row {
    margin-top: 10%;
    margin-bottom: 10%;
}

.remove-promo-code-button {
    margin: 5% 0;
    cursor: pointer;
}

.remove-promo-code-button:hover {
    color: black !important;
}

.notes-component {
    margin-top: 5%;
}

.mobile-summary-component {
    display: none;
}

@media (max-width: 768px) {
    .summary-container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        position: initial;
    }

    .mobile-summary-component {
        display: block;
    }
}