.address-header > img {
    width: 100%;
    height: 55vh;
    object-fit: cover;
}

.address-container {
    margin-top: 5%;
    margin-bottom: 5%;
}

.order-type-container {
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    position: absolute;
    left: 25%;
    top: 50%;
    background-color: white;
    padding: 1%;
}

.nav-tabs > .nav-item {
    width: 50%;
    margin-left: 0;
    text-align: center;
    margin-bottom: 0;
}

.basic-user > .nav-item {
    width: calc(100% / 3);
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    color: #DFDFDF !important;
    border-bottom: 2px solid #DFDFDF;
}

.nav-tabs > .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid #EF0000;
    color: #EF0000 !important;
}

.nav-tabs > .nav-link:focus, .nav-tabs .nav-link:hover {
    border: none;
    border-bottom: 2px solid #EF0000;
}

.tab-content {
    margin: 5% 0;
}

.offers {
    text-align: center;
    width: 50%;
    margin: 13% auto auto;
}

.offers .desktop-food-price {
    bottom: 10%;
}

.no-offers-div {
    margin: 5% 0;
}

.more-deals-button {
    width: 20%;
}

.form-delivery-area-container {
    width: 30%;
}

.form-address-container {
    width: 60%;
}

.form-branch-address-container {
    width: 90%;
}

.form-delivery-area-container > .form-group, .form-address-container > .form-group,
.form-branch-address-container > .form-group {
    margin: 0;
    height: 100%;
}

@media (max-width: 1024px) {
    .order-type-container {
        width: 60%;
        top: 30%;
        left: 21%;
    }

    .offers {
        width: 65%;
        margin: 24% auto 10% auto;
    }
}

@media (max-width: 768px) {
    .order-type-container {
        width: 90%;
        top: 25%;
        left: 5%;
    }

    .form-delivery-area-container, .form-address-container {
        width: 50%;
    }

    .mobile-button, .more-deals-button {
        width: 100%;
    }

    .offers {
        width: 90%;
        margin: 10% auto;
    }
}

@media (max-width: 600px) {
    .delivery-form-container {
        display: block !important;
    }

    .form-delivery-area-container, .form-address-container {
        width: 100%;
        padding: 0 !important;
        margin-bottom: 5%;
    }

    .delivery-form {
        padding: .5rem;
    }

    .form-delivery-area-container input {
        height: 30px !important;
    }

    .form-address-container input, .delivery-form > .btn {
        height: 44px !important;
    }

    .mobile-button {
        margin-top: 5%;
    }

    #delivery > form > .btn {
        height: 36px;
        padding-top: 1%;
    }
}

@media (max-width: 320px) {
    .order-type-container {
        top: 16%;
    }
}