.home-container {
    background: url("../images/home-background.jpg") no-repeat center;
    background-size: cover;
    min-height: 92.5vh;
}

.popup-container {
    background-color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 37%;
    padding: 3%;
    width: 25%;
    border-radius: 15px;
    transform: translateY(-50%);
}

.login-container {
    text-align: left;
    left: 37%;
}

.login-button {
    width: 100%;
    margin-top: 10%;
}

@media (max-width: 1024px) {
    .home-container {
        min-height: 94.15vh;
    }

    .popup-container {
        left: 25%;
        width: 50%;
    }
}

@media (max-width: 768px) {
    .popup-container {
        width: 90%;
        left: 5%;
    }

    .popup-container > .d-flex {
        display: block !important;
    }

    .branch-list-component > .p-2 {
        padding: 0 !important;
    }
}

@media (max-width: 600px) {
    .home-container {
        min-height: 92.15vh;
    }

    .popup-container {
        top: 55%;
        padding-top: 7%;
        padding-bottom: 7%;
    }

    .popup-container .login-button {
        margin-top: 5%;
        width: 60%;
    }
}