.offer-list > .p-2 {
    width: calc(100% / 3);
}

.food-list > .p-2 {
    width: calc(100% / 4);
}

.food-image-container {
    padding: 0 !important;
}

.food-image-container > img {
    width: 100%;
    border-radius: 0 15px 15px 0;
    max-height: 110px;
    height: 110px;
    object-fit: cover;
}

.food-details {
    text-align: left;
}

.food-details > .p-2 {
    width: 100%;
}

.food-name {
    padding-left: 5% !important;
    font-weight: normal;
}

.desktop-food-price {
    position: absolute;
    bottom: 40%;
    font-weight: bold;
}

.food-price {
    text-align: right;
}

.quantity-container {
    width: 50% !important;
}

.add-to-cart-form > .food-details > .p-2 {
    padding: 0 !important;
    margin: 5% 0;
}

.add-to-cart-form > .food-details > .p-2:first-child {
    margin-right: 2%;
}

.add-to-cart-form > .food-details > .p-2:last-child {
    margin-left: 2%;
}

.add-to-cart-button {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Suez One';
    width: 100%;
}

.add-to-cart-button > svg {
    margin-right: 5%;
    fill: currentcolor;
}

.food-container {
    margin-bottom: 5%;
}

.desktop-food-detail-container {
    background-color: #f3f3f3;
    border-radius: 15px;
}

.mobile-offer {
    height: 200px;
}

.mobile-offer > img {
    height: 100% !important;
    max-height: 100% !important;
}

@media (max-width: 1024px) {
    .add-to-cart-form > .d-flex {
        display: block !important;
    }

    .quantity-container {
        width: 100% !important;
    }

    .quantity-container > .custom-number > .custom-number-input {
        width: 100%;
    }

    .food-container {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 4% 0 0;
    }

    .offers > .row > .food-container {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
    }

    .food-price > span {
        width: 100%;
    }

    .desktop-food-price {
        bottom: 55%;
    }
}

@media (max-width: 768px) {
    .offer-list, .food-list {
        display: block !important;
    }

    .offer-list > .p-2, .food-list > .p-2 {
        width: 100%;
        padding: 0 !important;
        margin-bottom: 5%;
    }

    .quantity-container {
        margin-top: 10%;
    }

    .quantity-container > .custom-number > .custom-number-input {
        width: 80%;
    }

    .mobile-food-component > .p-2 {
        width: calc(100% / 3);
        padding-top: 0 !important;
    }

    .mobile-food-image-component {
        padding: 0 !important;
    }

    .mobile-food-component > .p-2:nth-child(3) {
        text-align: right;
    }

    .mobile-food-component > .p-2:nth-child(3) > .food-price:nth-child(2) {
        margin-top: 15%;
    }

    .add-to-cart-button {
        font-size: 11px;
        padding: 5% 0;
    }

    .desktop-food-price {
        bottom: 60%;
    }
}

@media (max-width: 600px) {
    .food-container, .offers > .row > .food-container {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10%;
        padding: 0 15px;
    }

    .food-image-container > img {
        height: 100px;
        border-radius: 15px;
    }

    .quantity-container, .mobile-food-component > .p-2:nth-child(3) > .food-price:nth-child(2) {
        position: absolute;
        bottom: 0;
        width: 28% !important;
        min-height: 38px;
    }

    .add-to-cart-button {
        min-height: inherit;
    }

    .mobile-food-component > .p-2:nth-child(3) > .food-price:nth-child(2) {
        width: 30% !important;
    }
}