.cart-component {
    padding-left: 0 !important;
}

.checkout-form {
    width: 35% !important;
    padding: 3% 0 !important;
}

.checkout-form > .row > .col:nth-child(2) {
    text-align: left;
}

.payment-detail-container {
    margin: 5% 0;
}

.show-modal {
    opacity: 1 !important;
    display: block !important;
    background-color: #00000066;
    text-align: center;
}

.modal-body {
    padding: 10%;
}

.modal-body > p {
    margin: 10% 0;
}

.modal-body > form > .login-button {
    margin-top: 0;
    margin-bottom: 10%;
}

@media (max-width: 768px) {
    .checkout-form > .row {
        display: block;
    }

    .payment-detail-container {
        margin: 10% 0;
    }

    .desktop-summary-component {
        display: none;
    }
}