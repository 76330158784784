.cart-container {
    position: fixed;
    width: 20%;
    min-height: 92.2vh;
    padding: 3% 0 3% 3%;
}

.delivery-address {
    color: #C8C8C8;
}

.col > .nav-link {
    padding: 0;
}

.row > .col:nth-child(2) {
    text-align: right;
}

.col > h4 {
    font-weight: bold;
}

.cart-total-container {
    position: absolute;
    bottom: 5%;
    width: 100%;
}

.cart-food-container {
    margin-bottom: 5%;
}

.cart-body-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 52vh;
}

.price-col {
    margin-right: 5%;
}

.mobile-cart-header, .mobile-checkout-button {
    display: none;
}

@media (max-width: 1024px) {
    .cart-container {
        width: 27%;
        padding: 2% 0 2% 2%;
    }

    .add-to-cart-form > .food-details > .p-2 {
        margin-left: 0 !important;
    }
}

@media (max-width: 768px) {
    .desktop-cart-header {
        display: none;
    }

    .mobile-cart-header {
        display: flex;
    }

    .mobile-checkout-button {
        display: block;
    }
}

@media (max-width: 600px) {
    .cart-body-container {
        max-height: 42vh;
        margin-top: 5%;
    }

    .cart-food-container > .row > .col:nth-child(3) {
        flex: 1 0 20%;
    }

    .desktop-food-image {
        display: none;
    }
}

@media (max-width: 320px) {
    .cart-body-container {
        max-height: 33vh;
    }
}