#footer {
    background-color: #000000;
    color: white;
    padding: 2% 0;
}

#footer .d-flex > .p-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#footer .nav-link {
    color: white !important;
    width: fit-content;
    padding: 0;
}

#footer .nav-link:hover {
    text-decoration: underline;
}

.footer-details {
    margin-top: 5%;
}

.social-icon {
    margin-right: 10%;
}

.footer-rights {
    text-align: center;
    margin-top: 5%;
}

@media (max-width: 768px) {
    #footer {
        text-align: center;
    }

    #footer .d-flex {
        display: block !important;
    }

    #footer .nav-link {
        margin: auto;
    }

    .social-icon:last-child {
        margin-right: 0;
    }

    .footer-container > .p-2 {
        margin-bottom: 10%;
    }
}