.about-us-container {
    min-height: 50vh;
}

.about-us-container, .about-us-detail-container {
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 18px;
}

.about-us-container, .about-us-detail-container:first-child {
    margin-top: 5%;
}

.about-us-container > img {
    width: 100%;
}

.about-us-detail-container > h1 {
    margin-bottom: 1%;
    font-size: 18px;
}